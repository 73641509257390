import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAppContext } from '../libs/contextLib';

export function AuthenticatedRoute({ children, ...rest}){
    const { pathname, search } = useLocation();
    const { isAuthenticated } = useAppContext();

    console.log(isAuthenticated);
    return (
        <Route {...rest}>
            {isAuthenticated ? (
                children
            ) : (
                <Redirect to={
                    `/Login?redirect=${pathname}${search}`
                } />
            )}
        </Route>
    )
}