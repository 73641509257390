import React, { useEffect, useRef } from 'react'

import gavswe from '../../Pictures/gavswe.png'
import doctahphil from '../../Pictures/doctahphil.png'
import johnswe from '../../Pictures/johnswe.png'
import yeeyee from '../../Pictures/yeeyee.png'

export function BagBoyRankings(props) {
    let userPics = [gavswe, doctahphil, johnswe, yeeyee];

    let userIndex = useRef();

    useEffect(() => {
        if (props.rankingsData._id.username === "gavswe"){
            userIndex.current = 0;
        }
        if (props.rankingsData._id.username === "doctahphil"){
            userIndex.current = 1;
        }
        if (props.rankingsData._id.username === "johnswe"){
            userIndex.current = 2;
        }
        if (props.rankingsData._id.username === "yeeyee"){
            userIndex.current = 3;
        }
    }, [])

    let rankings = props.rankingsData.rankings.map((team, index) => (
        <div key={team.team + "-" + props.rankingsData._id.username} className={`${index%2 === 0 ? "bg-white dark:bg-gray-700" : "bg-primary-100 dark:bg-gray-800"} ${index === 9 ? "rounded-b-md" : ""} h-8 md:h-10 text-gray-800 dark:text-gray-100 w-full font-semibold`} >
            <div className="w-1/12 mt-1 md:mt-2 inline-block text-center">{index + 1}</div>
            <div className="w-2/12 mt-1 md:mt-2 inline-block align-top">
                <img className="w-7 h-7 mx-auto" src={team.logo} alt="" draggable={false} />
            </div>
            <div className="w-9/12 mt-1 md:mt-2 text-sm md:text-base inline-block align-top text-center">
                {team.team}
            </div>
        </div>
    ))

    return (
        <div className="w-full md:w-1/2 lg:w-1/4 inline-block">
            <div className="mt-2 mx-2 bg-white dark:bg-gray-700 rounded-md shadow-md">
                <div className="rounded-t-md ">
                    <div className="h-16 font-semibold bg-primary-800 dark:bg-gray-700 border-b-4 dark:border-b-2 border-secondary-400  rounded-t-md">
                        <div className="mx-3 uppercase text-primary-100 text-center">
                            {props.rankingsData._id.username}
                        </div>
                        <div className="h-6 w-auto mx-3 px-2 bg-green-100 text-green-800 float-right rounded-full">
                            {props.rankingsData.dateTime.split("T")[0].split("-")[1] + "/" + props.rankingsData.dateTime.split("T")[0].split("-")[2]}
                        </div>
                    </div>
                    <div className="h-11 p-1">
                        <img className="w-20 h-20 mx-auto bg-white dark:bg-gray-500 shadow rounded-full relative -top-11"
                            src={userPics[userIndex.current]} />
                    </div>
                </div>
                <div className="rounded-b-md">
                    {rankings}
                </div>
            </div>
            <div className="h-10 mx-2 bg-dotted-background dark:bg-dotted-background-dark"></div>
        </div>
    )
}