import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib"

export function SignUp() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");

    const history = useHistory();
    const [newUser, setNewUser] = useState(null);
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return username.length > 0
            && email.length > 0
            && password.length > 0
            && password === confirmPassword;
    }

    function validateConfirmationForm() {
        return confirmationCode.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            const newUser = await Auth.signUp({
                username: username,
                password: password,
                attributes: {
                    email: email
                }
            });
            setIsLoading(false);
            setNewUser(newUser);
        }
        catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(username, confirmationCode);
            await Auth.signIn(username, password);
            
            userHasAuthenticated(true);
            history.push("/");
        }
        catch (e){
            alert(e.message);
            setIsLoading(false);
        }
    }

    function renderConfirmationForm() {
        return (
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-6 mb-4 dark:bg-gray-700" onSubmit={handleConfirmationSubmit}>
                <div className="mb-4">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Confirmation Code
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="number"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)} />
                    <div className="mt-1 text-xs text-gray-700 dark:text-gray-100 font-semibold">Please check your email for the code</div>
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-secondary-500  text-secondary-100 font-bold text-sm py-2 px-3 rounded hover:bg-secondary-500"
                        disabled={!validateConfirmationForm()}
                        type="submit">
                        Verify
                    </button>
                </div>
            </form>
        )
    }

    function renderForm() {
        return (
            <form className="bg-white shadow-lg rounded px-8 pt-6 pb-6 mb-4 dark:bg-gray-700" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Username
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Email
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Password
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Confirm Password
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className="text-sm text-gray-700 dark:text-gray-100 mb-6">
                    Password must be a least 8 characters long, and include:
                    <ul className="list-disc pl-8">
                        <li>An Uppercase Letter</li>
                        <li>A Lowercase Letter</li>
                        <li>A Number</li>
                        <li>A Symbol</li>
                    </ul>
                </div>
                <div>
                    <button className="bg-secondary-500  text-secondary-100 font-bold text-sm py-2 px-3 rounded hover:bg-secondary-800"
                        disabled={!validateForm()}
                        type="submit" >
                        Sign Up
                    </button>
                </div>
            </form>
        )
    }

    return (
        <div className="w-full h-auto max-w-xs mx-auto mt-10">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    )
}