import React, { useEffect, useRef, useState } from 'react'
import { TeamItem } from './UploadRankings/TeamItem';
import { TopTen } from './UploadRankings/TopTen';
import { Auth } from "aws-amplify"

export function UploadRankings() {
    let [isLoadingUserRankings, setIsLoadingUserRankings] = useState(true);
    let [isLoadingTeams, setIsLoadingTeams] = useState(true);
    let [teams, setTeams] = useState([]);
    let [filteredTeams, setFilteredTeams] = useState([]);
    let [selectedTeams, setSelectedTeams] = useState([]);
    let [saved, setSaved] = useState(true);

    let [error, setError] = useState(null);
    let [uploadSuccessfull, setUploadSuccessful] = useState(false);

    useEffect(async () => {
        fetch("https://zx6z6m7gl9.execute-api.us-east-1.amazonaws.com/dev/getUserRankings/" + await getUsername())
            .then(response => response.json())
            .then(data => {
                if (data.length === 0){
                    setSelectedTeams([]);
                    setIsLoadingUserRankings(false);
                }
                else {
                    setSelectedTeams(data[0].rankings);
                    setIsLoadingUserRankings(false);
                }
            });
    }, []);

    useEffect(() => {
        fetch("https://zx6z6m7gl9.execute-api.us-east-1.amazonaws.com/dev/teams")
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setTeams(data);
                setFilteredTeams(data);
                setIsLoadingTeams(false);
            });
    }, [])

    function search() {
        let text = document.getElementById("search-text").value.toLowerCase();
        setFilteredTeams(teams.filter(t => t.team.toLowerCase().includes(text)));
    }

    function teamClick(teamData) {
        let index = selectedTeams.findIndex(t => t.team === teamData.team);
        if (index === -1) {
            if (selectedTeams.length < 10) {
                setSelectedTeams(oldArray => [...oldArray, teamData])
                setSaved(false);
                document.getElementById("search-text").value = "";
                search();
            }
        }
        else {
            setSelectedTeams(selectedTeams.filter(t => t.team !== teamData.team))
            setSaved(false);
        }
    }

    async function getUsername() {
        let info = await Auth.currentUserInfo();
        return info.username
    }

    async function upload() {
        console.log(selectedTeams);
        if (selectedTeams.length !== 10) {
            alert("Please select 10 teams.")
            return;
        }
        let rankingsObj = {
            "username": await getUsername(),
            "rankings": selectedTeams
        }

        fetch("https://zx6z6m7gl9.execute-api.us-east-1.amazonaws.com/dev/UploadRankings", {
            method: "POST",
            body: JSON.stringify(rankingsObj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUploadSuccessful(true);
                    // setIsLoading(false);
                    setSaved(true);
                    setError(false);
                    console.log(result);
                },
                (error) => {
                    setError(error);
                    // console.log(error);
                }
            )
    }

    function reorder(index, hoverIndex) {
        
        console.log("ya");
        let nodes = document.getElementById("selected-teams-container").childNodes;

        let indexSpot = 0;
        let hoverIndexSpot = 0;
        for (let i = 0; i < nodes.length; i++){
            if ("selected-team-item-" + index === nodes[i].id) {
                indexSpot = i;
            }
            if ("selected-team-item-" + hoverIndex === nodes[i].id) {
                hoverIndexSpot = i;
            }
        }

        console.log("indexSpot: " + indexSpot);
        console.log("hoverIndexSpot: " + hoverIndexSpot);

        const element = document.getElementById("selected-team-item-" + index);
        const afterElement = document.getElementById("selected-team-item-" + hoverIndex);
        const container = document.getElementById("selected-teams-container");

        if (indexSpot > hoverIndexSpot){
            container.insertBefore(element, afterElement);
        }
        else{
            container.insertBefore(element, afterElement.nextElementSibling);
        }
    }

    function setNewOrder(){
        let nodes = document.getElementById("selected-teams-container").childNodes;

        let newOrder = [];

        for (let i = 0; i < nodes.length; i++){
            let team = nodes[i].childNodes[2].textContent;
            if (team !== ""){
                newOrder.push(selectedTeams.find(t => t.team === team));
            }
        }

        console.log(newOrder);
        setSelectedTeams(newOrder);
        setSaved(false);
    }

    if (isLoadingUserRankings || isLoadingTeams) {
        return (
            <div className="text-center dark:text-gray-100">Loading...</div>
        )
    }
    else {
        return (
            <React.Fragment>
                <div className="flex-1 h-64 flex flex-col lg:block">
                    <div className="w-full lg:w-1/4 xl:w-1/5 lg:h-full bg-white dark:bg-gray-700 inline-block align-top relative z-10">
                        <TopTen selectedTeams={selectedTeams} teamClick={teamClick} upload={upload} reorder={reorder} setNewOrder={setNewOrder} saved={saved} setSaved={setSaved} />
                    </div>
                    <div className="flex-1 h-10 lg:w-3/4 xl:w-4/5 lg:h-full inline-block align-top">
                        <div className="flex flex-col h-full">
                            <div className="h-14 shadow-lg z-10 dark:bg-gray-700">
                                <input className="block mx-auto my-2 text-gray-700 text-sm font-bold shadow border dark:border-orange-500 rounded w-80 py-2 px-3 dark:bg-gray-700 dark:text-gray-100" id="search-text" type="text" placeholder="Search Teams" onChange={search} />
                            </div>
                            <div className="px-10 h-full overflow-y-scroll text-gray-600 dark:text-gray-100 font-semibold">
                                {filteredTeams.map(team => (
                                    <TeamItem key={team.team} teamData={team} teamClick={teamClick} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}