import React, { useEffect, useState } from 'react';
import { Auth } from "aws-amplify"
import { useHistory } from 'react-router-dom'
import { Header } from './Components/Header';
import './tailwind.generated.css';
import { AppContext } from './libs/contextLib'
import Routes from './Components/Routes';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== "No current user") {
        alert(e)
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/Login");
  }

  return (
    !isAuthenticating && (
      <div className="flex flex-col h-screen">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Header handleLogout={handleLogout} />
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;
