import React, { useState } from 'react'

export function TopTenTeamItem(props) {
    const [isHovering, setIsHovering] = useState(false);

    function setHover(hoverState) {
        setIsHovering(hoverState)
    }

    return (
        <div
            id={"selected-team-item-" + props.index}
            className="lg:h-14 mx-auto max-w-xs p-1 lg:p-2 lg:mx-3 text-sm lg:text-base font-semibold text-gray-600 dark:text-gray-100 overflow-ellipsis whitespace-nowrap cursor-move flex"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}

            draggable={true}
            onDragStart={(e) => props.setDragTeam(e, props.index)}
            onDragOver={(e) => props.setHoverOverTeam(e, props.index)}
            // onDragEnd={props.dragEnd}

            onDrop={props.dragEnd} >
            <div className="inline-block w-6 mr-2 align-top lg:pt-2 text-center">
                {isHovering
                    ? <svg onClick={() => props.teamClick(props.teamData)} className="w-6 h-6 text-red-600 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                    : props.index + 1
                }
            </div>
            <div className="w-9 inline-block align-top select-none">
                <img className="w-5 h-5 lg:w-9 lg:h-9 select-none" src={props.teamData.logo} alt="" draggable={false} />
            </div>
            <div className="flex-1 inline-block align-top lg:pt-2 pl-2 overflow-hidden overflow-ellipsis">
                {props.teamData.team}
            </div>
        </div>
    )
}