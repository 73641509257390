import React from 'react'

export function NotFound() {
    return (
        <div className="text-center">
            <div className="text-5xl text-gray-500">
                404
            </div>
            <div className="w-full font-bold text-gray-700">
                This ain't it, chief.
            </div>
        </div>
    )
}