import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from '../libs/contextLib';

export function UnauthenticatedRoute({ children, ...rest}){
    const { isAuthenticated } = useAppContext();

    console.log(isAuthenticated);
    return (
        <Route {...rest}>
            {!isAuthenticated ? (
                children
            ) : (
                <Redirect to="/" />
            )}
        </Route>
    )
}