import React from 'react'
import { AggregateTeamLine } from './AggregateTeamLine'
import MondayRankingsLogo from "../../MondayRankingsLogoDark.svg";


export function AggregateRankings(props) {
    let rankings = <div></div>;

    if (props.aggregateRankings.length === 0){
        rankings = 
        <div className="text-center text-blue-900 dark:text-blue-300">
            <div className="w-20 mx-auto mt-10 text-right">
                <svg className="w-6 h-6 ml-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path></svg>
            </div>
            <svg className="w-20 h-20 mx-auto -top-4 relative" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path></svg>
            <div className="font-bold">
                No rankings for current week
            </div>
        </div>
    }
    else {
        rankings = props.aggregateRankings.map((team, index) => (
            <AggregateTeamLine key={team.team} team={team} index={index} />
        ))
    }

    return (
        <div className="w-full md:w-1/2 mx-auto">
            <div className="mt-2 mx-2 bg-white rounded-md shadow-md dark:bg-gray-700">
                <div className="rounded-t-md ">
                    <div className="h-16 pt-4 font-semibold bg-primary-800 dark:bg-gray-700 border-b-4 dark:border-b-2 border-secondary-400 flex justify-between rounded-t-md">
                    </div>
                    <div className="h-11 p-1">
                        <div className="w-20 h-20 mx-auto bg-white dark:bg-gray-500 shadow rounded-full relative -top-11 text-6xl text-center pt-2" >
                            &#128176;
                            {/* &#127877;&#127995; */}
                            {/* <img className="h-16 mx-auto mt-1" src={MondayRankingsLogo} alt="" /> */}
                        </div>
                    </div>
                </div>
                <div className="rounded-b-md h-80 md:h-100 overflow-y-auto">
                    {rankings}
                </div>
            </div>
            <div className="h-10 mx-2 bg-dotted-background dark:bg-dotted-background-dark"></div>
        </div>
    )
}