import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib"

export function ForgotPassword() {
    const [username, setUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [code, setCode] = useState("");

    const history = useHistory();
    const [passwordEmailSent, setPasswordEmailSent] = useState(false);
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return username.length > 0;
    }

    function validateConfirmationForm() {
        return newPassword.length > 0 
            && code.length > 0
            && newPassword === confirmPassword;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.forgotPassword(username);

            setIsLoading(false);
            setPasswordEmailSent(true);
        }
        catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.forgotPasswordSubmit(username, code, newPassword);
            // await Auth.signIn(username, new_password);
            
            userHasAuthenticated(false);
            history.push("/Login");
        }
        catch (e){
            alert(e.message);
            setIsLoading(false);
        }
    }

    function renderNewPasswordForm() {
        return (
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-6 mb-4 dark:bg-gray-700" onSubmit={handleConfirmationSubmit}>
                <div className="mb-4">
                    <label className="block text-orange-500 dark:text-orange-100 text-sm font-bold mb-2">
                        Code
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="number"
                        value={code}
                        onChange={(e) => setCode(e.target.value)} />
                    <div className="mt-1 text-xs text-gray-700 dark:text-gray-100 font-semibold">Please check your email for the code</div>
                </div>
                <div className="mb-6">
                    <label className="block text-orange-500 dark:text-orange-100 text-sm font-bold mb-2">
                        New Password
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-orange-500 dark:text-orange-100 text-sm font-bold mb-2">
                        Confirm Password
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className="text-sm text-gray-700 dark:text-gray-100 mb-6">
                    Password must be a least 8 characters long, and include:
                    <ul className="list-disc pl-8">
                        <li>An Uppercase Letter</li>
                        <li>A Lowercase Letter</li>
                        <li>A Number</li>
                        <li>A Symbol</li>
                    </ul>
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-orange-500  text-orange-100 font-bold text-sm py-2 px-3 rounded hover:bg-orange-500"
                        disabled={!validateConfirmationForm()}
                        type="submit">
                        Submit
                    </button>
                </div>
            </form>
        )
    }

    function renderNewPasswordRequest() {
        return (
            <form className="bg-white shadow-lg rounded px-8 pt-6 pb-6 mb-4 dark:bg-gray-700" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-orange-500 dark:text-orange-100 text-sm font-bold mb-2">
                        Username
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div>
                    <button className="bg-orange-500  text-orange-100 font-bold text-sm py-2 px-3 rounded hover:bg-orange-800"
                        disabled={!validateForm()}
                        type="submit" >
                        Submit
                    </button>
                </div>
            </form>
        )
    }

    return (
        <div className="w-full h-auto max-w-xs mx-auto mt-10">
            {!passwordEmailSent ? renderNewPasswordRequest() : renderNewPasswordForm()}
        </div>
    )
}