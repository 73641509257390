import React, { useState, useEffect } from 'react'
import { AggregateRankings } from './Dashboard/AggregateRankings';
import { BagBoyRankings } from './Dashboard/BagBoyRankings';

export function Dashboard() {
    let [aggregateRankings, setAggregateRankings] = useState([]);
    let [bagBoysRankings, setBagBoysRankings] = useState([]);
    let [isLoadingBagBoys, setIsLoadingBagBoys] = useState(true);
    let [isLoadingAggregate, setIsLoadingAggregate] = useState(true);

    let [showSection, setShowSection] = useState("Aggregate");

    useEffect(() => {
        fetch("https://zx6z6m7gl9.execute-api.us-east-1.amazonaws.com/dev/currentWeekRankings")
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setAggregateRankings(data);
                setIsLoadingAggregate(false);
            });
    }, []);

    useEffect(() => {
        fetch("https://zx6z6m7gl9.execute-api.us-east-1.amazonaws.com/dev/bagBoysRankings")
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setBagBoysRankings(data);
                setIsLoadingBagBoys(false);
            });
    }, []);

    if (isLoadingBagBoys || isLoadingAggregate) {
        return <div className="text-center dark:text-gray-100">Loading...</div>
    }
    else {
        return (
            <React.Fragment>
                <div className="py-3 bg-white shadow-md dark:bg-gray-900 dark:text-gray-100">
                    <div className="w-64 mx-auto h-10 rounded-full bg-primary-800 font-semibold dark:bg-gray-700 dark:text-gray-100">
                        <div className="inline-block w-1/2">
                            <div className={`${showSection === "Aggregate" ? "bg-primary-100 text-primary-800" : "bg-none text-primary-100"} text-center rounded-full h-8 mt-1 ml-1 pt-1 cursor-pointer`} onClick={() => setShowSection("Aggregate")}>
                                Aggregate
                            </div>
                        </div>
                        <div className="inline-block w-1/2">
                            <div className={`${showSection === "Individual" ? "bg-primary-100 text-primary-800" : "bg-none text-primary-100"} text-center rounded-full h-8 mt-1 mr-1 pt-1 cursor-pointer`} onClick={() => setShowSection("Individual")}>
                                Individual
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 lg:mx-10 pb-8 overflow-y-auto">
                    <div className={`${showSection === "Aggregate" ? "block" : "hidden"}`}>
                        <AggregateRankings aggregateRankings={aggregateRankings} />
                    </div>
                    <div className={`${showSection === "Individual" ? "md:flex" : "hidden"} md:justify-center`}>
                        {bagBoysRankings.map(rankings => (
                            <BagBoyRankings key={rankings._id.username} rankingsData={rankings} />
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}