import React from 'react'

export function AggregateTeamLine(props){
    let delta = <div></div>

    if (props.team.delta > 0){
        delta = <div className="mx-auto flex justify-center text-green-600 dark:text-green-400">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg>
            <div>
                {props.team.delta}
            </div>
        </div>
    }
    else if (props.team.delta < 0){
        delta = <div className="mx-auto flex justify-center text-red-600 dark:text-red-400">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg>
            <div>
                {Math.abs(props.team.delta)}
            </div>
        </div>
    }
    else {
        delta = <div>-</div>
    }

    return (
        <div key={props.team.team} className={`${props.index%2 === 0 ? "bg-white dark:bg-gray-700" : "bg-primary-100 dark:bg-gray-800"} h-8 md:h-10 text-primary-800 dark:text-gray-100 w-full font-semibold`} >
            <div className="w-1/12 mt-1 md:mt-2 inline-block text-center align-top">
                {props.team.rank}
            </div>
            <div className="w-2/12 mt-1 md:mt-2 inline-block text-center align-top">
                {delta}
            </div>
            <div className="w-2/12 mt-1 md:mt-2 inline-block align-top">
                <img className="w-7 h-7 mx-auto" src={props.team.logo} alt="" draggable={false} />
            </div>
            <div className="w-5/12 mt-1 md:mt-2 text-sm md:text-base inline-block align-top text-center">
                {props.team.team}
            </div>
            <div className="w-2/12 mt-1 md:mt-2 text-sm md:text-base inline-block align-top text-center">
                {props.team.firstPlaceVotes > 0 ? props.team.votes + " (" + props.team.firstPlaceVotes + ")" : props.team.votes}
            </div>
        </div>
    )
}