import React, { useRef } from 'react'
import { TopTenTeamItem } from './TopTenTeamItem'

export function TopTen(props) {
    let dragTeam = useRef(null);
    let oldHoverOverTeam = useRef(-1);
    let hoverOverTeam = useRef(null);

    function setDragTeam(e, team) {
        dragTeam.current = team;
        console.log(dragTeam);
    }

    function setHoverOverTeam(e, team) {
        e.preventDefault();

        if (team !== hoverOverTeam.current){
            oldHoverOverTeam.current = team;
            if (oldHoverOverTeam.current !== hoverOverTeam.current){
                hoverOverTeam.current = team;
                console.log(dragTeam.current, hoverOverTeam.current);
                props.reorder(dragTeam.current, hoverOverTeam.current);
            }
        }
    }
    
    function dragEnd(e){
        props.setNewOrder();
    }

    console.log(props.selectedTeams)
    return (
        <div className="flex flex-col h-full">
            <div className="h-10 lg:h-14 lg:mx-3 text-2xl text-center py-1 lg:pt-3 shadow-lg lg:shadow-none lg:border-b-2 lg:border-gray-200 lg:dark:border-gray-500 flex justify-between">
                <div className="mx-5 mt-1 uppercase text-gray-700 dark:text-gray-100 font-semibold text-lg">
                    Rankings
                </div>
                <button className={`${props.saved ? "bg-white dark:bg-gray-700 text-secondary-600 cursor-default" : "bg-secondary-600 text-secondary-100 hover:bg-secondary-800 cursor-pointer"} w-24 font-semibold text-base py-1 px-1 rounded`}
                    onClick={props.upload}
                    disabled={props.saved} >
                    {props.saved 
                        ?   <div className="flex justify-around align-middle text-green-600 dark:text-green-100">
                                <div className="">
                                    Saved
                                </div>
                                <svg className="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                            </div> 
                        :   <div className="flex justify-around align-middle">
                                <div>
                                    Save
                                </div>
                                <svg className="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"></path></svg>
                            </div>}
                </button>
            </div>
            <div id="selected-teams-container" className="flex-1 mt-1 h-76 lg:h-150 overflow-y-auto">
                {props.selectedTeams.map((s, index) => (
                    <TopTenTeamItem key={s.team}
                        teamData={s}
                        index={index}
                        teamClick={props.teamClick}
                        setDragTeam={setDragTeam}
                        setHoverOverTeam={setHoverOverTeam}
                        dragEnd={dragEnd} />
                ))}
                {[...Array(10 - props.selectedTeams.length).keys()].map((index) => (
                    <div key={index} className="lg:h-14 mx-auto max-w-xs p-1 lg:p-2 lg:mx-3 text-sm lg:text-base font-semibold text-gray-600 dark:text-gray-100">
                        <div className="inline-block w-6 mr-2 align-top lg:pt-2 text-center">
                            {index + props.selectedTeams.length + 1}
                        </div>
                        <div className="inline-block align-top">
                        </div>
                        <div className="inline-block align-top lg:pt-2 pl-2">
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}