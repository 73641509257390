import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useAppContext } from "../libs/contextLib"
import { useHistory } from "react-router-dom";

export function Login() {
    const { userHasAuthenticated } = useAppContext()
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            await Auth.signIn(username, password);
            userHasAuthenticated(true);
            history.push("/")
        }
        catch (e) {
            alert(e.message);
        }
    }

    return (
        <div className="w-full h-auto max-w-xs mx-auto mt-10">
            <form className="bg-white dark:bg-gray-700 shadow-md rounded px-8 pt-6 pb-6 mb-4" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Username
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-secondary-500 dark:text-secondary-100 text-sm font-bold mb-2">
                        Password
                    </label>
                    <input className="text-gray-700 text-sm font-bold shadow border rounded w-full py-2 px-3"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="flex items-center justify-between">
                    <button className="w-24 border-2 border-secondary-500 bg-secondary-500 text-secondary-50 font-bold text-sm py-1 px-3 rounded hover:bg-secondary-600"
                        disabled={!validateForm()}
                        type="submit" >
                        Log In
                    </button>
                    <button className="w-24 border-2 border-secondary-500 text-secondary-500 dark:text-secondary-100 font-bold text-sm py-1 px-3 rounded hover:bg-secondary-300"
                        onClick={() => history.push("/Signup")}>
                        Sign Up
                    </button>
                </div>
                <div className="text-center text-secondary-500 dark:text-secondary-100 text-xs font-bold mt-4 cursor-pointer" onClick={() => history.push("/ForgotPassword")}>
                    Forgot Password?
                </div>
            </form>
        </div>
    )
}