import React from 'react'
import { useHistory } from "react-router-dom";
import { useAppContext } from '../libs/contextLib';
import MondayRankingsLogo from "../MondayRankingsLogo.svg";

export function Header(props){
    const { isAuthenticated } = useAppContext();
    const history = useHistory();

    return (
        <div className="flex items-center w-full border-t-4 dark:border-b-2 dark:border-t-0 border-secondary-400 bg-primary-800 dark:bg-gray-800 text-primary-100 h-16 shadow-md">
            <div className="flex-1 w-1/2 py-2 pl-2 text-2xl md:text-4xl">
                {/* &#128176; Boys */}
                <img className="h-16 ml-5 mt-1 cursor-pointer" src={MondayRankingsLogo} alt="" onClick={() => history.push("/Dashboard")} />
            </div>
            <div className="w-16 md:w-20 mr-3 text-center">
                <button className="font-bold text-sm md:text-lg py-2 rounded"
                    onClick={() => history.push("/Dashboard")} >
                    Rankings
                </button>
            </div>
            <div className="w-16 md:w-20 mr-3 text-center">
                <button className="font-bold text-sm md:text-lg py-2 rounded"
                    onClick={() => history.push("/UploadRankings")} >
                    Upload
                </button>
            </div>
            <div className="w-16 md:w-20 mr-3 text-center">
                <button className="font-bold text-sm md:text-lg py-2 rounded"
                    onClick={() => isAuthenticated
                        ? props.handleLogout()
                        : history.push("/Login")} >
                    {isAuthenticated ? "Logout" : "Login"}
                </button>
            </div>
        </div>
    )
}