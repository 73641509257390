import React from 'react'
import { Route, Switch } from "react-router-dom"

import { UnauthenticatedRoute } from './UnauthenticatedRoute'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { UploadRankings } from './UploadRankings'
import { Login } from './Login'
import { SignUp } from './Signup'
import { NotFound } from './NotFound'
import { Dashboard } from './Dashboard'
import { ForgotPassword } from './ForgotPassword'

export default function Routes() {
    return (
        <Switch>
            {/* <Route path="/scoreboard" component={Scoreboard} /> */}
            <AuthenticatedRoute exact path="/UploadRankings">
                <UploadRankings />
            </AuthenticatedRoute>

            <UnauthenticatedRoute exact path="/Login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/Signup">
                <SignUp />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/ForgotPassword">
                <ForgotPassword />
            </UnauthenticatedRoute>

            <Route exact path="/">
                <Dashboard />
            </Route>
            <Route exact path="/Dashboard">
                <Dashboard />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    )
}