import React from 'react'

export function TeamItem(props) {
    return (
        <div className="inline-block w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:1/5">
            <div className="h-10 lg:h-12 m-1 lg:m-3 pt-1 bg-white dark:bg-gray-700 shadow rounded-md cursor-pointer" onClick={() => props.teamClick(props.teamData)}>
                <div className="w-1/6 inline-block align-top">
                    <img className="w-7 h-7 lg:w-10 lg:h-10 ml-3" src={props.teamData.logo} alt="" draggable={false} />
                </div>
                <div className="w-5/6 mt-1 lg:mt-2 text-sm lg:text-base inline-block align-top text-center">
                    {props.teamData.team}
                </div>
            </div>
        </div>
    )
}